import React from 'react';
import logo from './logo.svg';
import './App.css';
import Chart from './components/chart'
import { useState, useEffect } from 'react';
import axios from 'axios';

function App() {
  //const [count, setCount] = useState(0);
  const [datas, setDatas] = useState<any[]>([]);
  const [loaded, setLoaded] = useState(false);

  const load = async () => {
    const response = await axios
      .get("http://tags.paranet.ru/load_tag_datas.php?id=FZ-01-TEMPERATURE") //FZ-01-Humidity
      .then((result) => {
        setDatas(result.data);
        //setCount(count + 1);
        console.log('updated');
        if (datas.length > 0) {
          setLoaded(true);
        }
      });
  };

  const handleClick = async () => {
    //load();
    setLoaded(false);
  }

  useEffect(() => {
    if (!loaded) {
      load();
    }
  }/*, [show]*/);

  return (
    <>
      <div className="App">
        <div>
          <button onClick={handleClick}>
            Обновить...
          </button>
        </div>
        {datas.length > 0 && (
          <Chart datas={datas} />
        )}
      </div>
    </>
  );
}

export default App;
