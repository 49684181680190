import { Chart } from "react-google-charts";

export const options = {
    chart: {
        title: "Температура и влажность в помещении",
    },
    width: 900,
    height: 500,
    series: {
        // Gives each series an axis name that matches the Y-axis below.
        0: { axis: "Temps" },
        1: { axis: "Humidity" },
    },
    axes: {
        // Adds labels to each axis; they don't have to match the axis names.
        y: {
            Temps: { label: "Температура" },
            Humidity: { label: "Влажность" },
        },
    },
};

const charts = (props: {
    datas: any[]
}) => {
    const { datas } = props;

    const data: any = [
        [{ type: "date", label: "Время" }, "Температура в помещении", "Влажность в помещении"],
    ];

    for (var i = 0; i < datas.length; i++) {
        data.push([new Date(datas[i]['time']), Number(datas[i]['value']), 0.1]);
    }


    return (
        <>
            {datas.length > 0 && (
                <Chart
                    chartType="Line"
                    data={data}
                    options={options}
                    width={"100%"}
                    height={"400px"}
                />
            )}
        </>
    );
};
export default charts;